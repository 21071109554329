import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
};

const answerGroups = {
  SatisfactionLevels: ["راضي تماماً", "راضي", "محايد", "غير راضي"],
  QualityAssessment: ["ممتاز", "جيد", "محايد", "لا يوجد"],
  PerformanceRatings: ["ممتاز", "جيد", "محايد", "ضعيف"],
  FrequencyResponses: ["دائما", "غالبا", "محايد", "أبدا"],
  AwarenessLevels: ["على اطلاع دائم", "مطلع", "محايد", "غير متاحة"],
  InformationAwareness: ["على اطلاع دائم", "مطلع", "محايد", "غير مطلع"],
  KnowledgeClarity: ["تماما", "إلى حد ما", "محايد", "لا أعلم"],
  QualityAssessmentExtended: ["ممتازة", "جيدة", "لا أعلم", "ضعيفة"],
  SatisfactionSpectrum: ["عالية", "جيدة", "لا أعلم", "غير جيدة"],
  PrideLevels: ["فخور جدا", "فخور", "محايد", "غير فخور"],
  HappinessSpectrum: ["سعيد جدا", "سعيد", "محايد", "غير سعيد"],
  DecisionMaking: ["لا أفكر بالمغادرة", "أفكر مليا", "محايد", "دون تردد"],
  ClarityLevels: ["واضح جداً", "واضح", "لا أعلم", "غير واضح"],
};



const questionsData = [
  { title: "ثقافة وقيم الشركة", question: "رضاك عن الشركة بشكل عام", answerGroupKey: "SatisfactionLevels" },
  { title: "ثقافة وقيم الشركة", question: "تقدر الشركة العمل الجيد من الموظف", answerGroupKey: "FrequencyResponses" },
  { title: "ثقافة وقيم الشركة", question: "رضاك عن مستوى الوضوح والشفافية في الشركة", answerGroupKey: "SatisfactionLevels" },
  { title: "ثقافة وقيم الشركة", question: "تشجع الشركة الموظفين على الإبداع والابتكار من أجل التحسين المستمر", answerGroupKey: "FrequencyResponses" },
  { title: "ثقافة وقيم الشركة", question: "لدي فهم كامل لاستراتيجية الشركة", answerGroupKey: "FrequencyResponses" },
  { title: "ثقافة وقيم الشركة", question: "لدي ثقة في قيادة هذه الشركة", answerGroupKey: "FrequencyResponses" },
  { title: "ثقافة وقيم الشركة", question: "مدى رضاك عن سياسة الباب المفتوح", answerGroupKey: "SatisfactionLevels" },

  { title: "الخدمات والتطوير", question: "تتبنى الشركة العمل بروح الفريق الواحد نحو تحقيق أهداف واضحة", answerGroupKey: "FrequencyResponses" },
  { title: "الخدمات والتطوير", question: "توفر الشركة الفرص الكافية لتطوير قدراتي الوظيفية وتنميتها", answerGroupKey: "FrequencyResponses" },
  { title: "الخدمات والتطوير", question: "مدى رضاك عن خدمة التأمين الطبي المقدمة", answerGroupKey: "SatisfactionLevels" },

  { title: "التواصل الداخلي والخارجي", question: "تهتم الشركة بالجانب الاجتماعي للموظفين ومشاركتهم أفراحهم وأحزانهم", answerGroupKey: "FrequencyResponses" },
  { title: "التواصل الداخلي والخارجي", question: "يتم إبراز صورة الشركة إيجابا بالشكل المطلوب في وسائل التواصل الاجتماعية", answerGroupKey: "FrequencyResponses" },
  { title: "التواصل الداخلي والخارجي", question: "تعزز الشركة الاتصال الفعال بين موظفيها", answerGroupKey: "FrequencyResponses" },
  { title: "التواصل الداخلي والخارجي", question: "مدى حرص الشركة على تظلمات الموظفين والعمل على معالجتها وإفادتهم", answerGroupKey: "FrequencyResponses" },
  
  { title: "العمليات الداخلية", question: "مستوى الوضوح في إجراءات عمل الإدارات والقطاعات", answerGroupKey: "ClarityLevels" },
  { title: "العمليات الداخلية", question: "مدى معرفة الموظفين بحقوقهم وواجباتهم واطلاعهم على السياسات والإجراءات", answerGroupKey: "AwarenessLevels" },
  { title: "العمليات الداخلية", question: "مدى رضاك عن نظام (سهل) للشركة في تسهيل العمليات الداخلية", answerGroupKey: "SatisfactionLevels" },
  { title: "العمليات الداخلية", question: "يتم إطلاع الموظفين بمهام القطاعات/الإدارات الأخرى والصلاحيات", answerGroupKey: "FrequencyResponses" },
  { title: "العمليات الداخلية", question: "مستوى التعامل مع الطلبات من قبل قطاعات/إدارات الشركة وإنجازها في الوقت المطلوب", answerGroupKey: "PerformanceRatings" },



  { title: "بيئة العمل", question: "تتم المساندة والتوجيه من الرئيس المباشر لتحسين أدائي الوظيفي", answerGroupKey: "FrequencyResponses" },
  { title: "بيئة العمل", question: "هل تتلقى إشادة من مديرك عندما تنجز عمل بشكل جيد", answerGroupKey: "FrequencyResponses" },
  { title: "بيئة العمل", question: " هل تعتقد انك تحصل على مساواة في المعاملة  مابين أعضاء الفريق ", answerGroupKey: "FrequencyResponses" },
  { title: "بيئة العمل", question: "تلقيت التدريب اللازم للقيام بأعمالي على الوجه المطلوب", answerGroupKey: "FrequencyResponses" },
  { title: "بيئة العمل", question: "مستوى إطلاعي على الأمور التي لها تأثير مباشر أو غير مباشر على مهامي", answerGroupKey: "InformationAwareness" },
  { title: "بيئة العمل", question: "أعلم تماما ما هو العمل المطلوب/الدور الذي أقوم به", answerGroupKey: "KnowledgeClarity" },
  { title: "بيئة العمل", question: "يدعم المدراء التعاون بين قطاعات الشركة لتحقيق الأهداف المرجوة", answerGroupKey: "FrequencyResponses" },
  { title: "بيئة العمل", question: "هل بيئة العمل مشجعة لتحقيق التوازن بين العمل ونواحي الحياة الأخرى", answerGroupKey: "FrequencyResponses" },
  { title: "بيئة العمل", question: "مدى تفهم وتعاون الشركة في حالة الظروف الشخصية الطارئة", answerGroupKey: "FrequencyResponses" },
  { title: "بيئة العمل", question: "مستوى رضاك بساعات الحضور والإنصراف المرن", answerGroupKey: "SatisfactionLevels" },
  { title: "بيئة العمل", question: "مدى رضاك عن برامج الاندماج الوظيفي من خلال المبادرات والفعاليات والأنشطة الداخلية", answerGroupKey: "SatisfactionLevels" },
  { title: "بيئة العمل", question: "الأنشطة والمبادرات التي تقوم بها الشركة منافسة للجهات الخارجية", answerGroupKey: "QualityAssessmentExtended" },
  { title: "بيئة العمل", question: "كيف تصنف المعنويات العامة للموظفين", answerGroupKey: "SatisfactionSpectrum" },
  { title: "بيئة العمل", question: "أشعر أن هناك روح التعاون داخل هذه الشركة ", answerGroupKey: "FrequencyResponses" },
  { title: "بيئة العمل", question: "مدى اعتزازك وولائك للشركة", answerGroupKey: "PrideLevels" },


  { title: "المسار المهني", question: "مدى سعادتك بانضمامك للشركة", answerGroupKey: "HappinessSpectrum" },
  { title: "المسار المهني", question: "مدى شعورك بالأمان الوظيفي  في الشركة", answerGroupKey: "FrequencyResponses" },
  { title: "المسار المهني", question: "هناك فرص لتقدمي الخاص داخل هذه الشركة", answerGroupKey: "FrequencyResponses" },
  { title: "المسار المهني", question: "في حال حصولك على عرض أفضل هل تفكر بالمغادرة", answerGroupKey: "DecisionMaking" },
  { title: "المسار المهني", question: "أزكي جهة عملي بصفتها مكان جيد للعمل", answerGroupKey: "FrequencyResponses" },
  { title: "المسار المهني", question: "مستوى الرضا عن نظام الحوافز والبدلات", answerGroupKey: "SatisfactionLevels" },
];







const data2 = {
  notes:"",
  sections: {
    section1: {
      id: "section1",
      title: "ثقافة وقيم الشركة",
      questions: {
        question1: {
          id: "question1",
          title:
            " رضاك عن  الشركة بشكل عام",
          value: "",
        },
        question2: {
          id: "question2",
          title: " تقدر الشركة العمل الجيد من الموظف",
          value: "",
        },
        question3: {
          id: "question3",
          title: "رضاك عن مستوى الوضوح والشفافية في الشركة",
          value: "",
        },
        question4: {
          id: "question4",
          title: "تشجع الشركة الموظفين على الإبداع والابتكار من أجل التحسين المستمر",
          value: "",
        },
        question5: {
          id: "question5",
          title: "مدى رضاك عن سياسة الباب المفتوح",
          value: "",
        },
      
      },
    },
    section2: {
      id: "section2",
      title: "الخدمات والتطوير",
      questions: {
        question6: {
          id: "question6",
          title: "تتبنى الشركة العمل بروح الفريق الواحد نحو تحقيق أهداف واضحة",
          value: "",
        },
        question7: {
          id: "question7",
          title:
            "توفر الشركة الفرص الكافية لتطوير قدراتي الوظيفية وتنميتها",
          value: "",
        },
        question8: {
          id: "question8",
          title: "مدى رضاك عن خدمة التأمين الطبي المقدمة",
          value: "",
        },
      
      },
    },
    section3: {
      id: "section3",
      title: "التواصل الداخلي والخارجي",
      questions: {
        question10: {
          id: "question10",
          title:
            "تهتم الشركة بالجانب الاجتماعي للموظفين ومشاركتهم أفراحهم وأحزانهم",
          value: "",
        },
        question11: {
          id: "question11",
          title:
            " يتم إبراز صورة الشركة إيجابا بالشكل المطلوب في وسائل التواصل الاجتماعية",
          value: "",
        },
        question12: {
          id: "question12",
          title: "تعزز الشركة الاتصال الفعال بين موظفيها",
          value: "",
        },
        question13: {
          id: "question13",
          title:
            "مدى حرص الشركة  على تظلمات الموظفين والعمل على معالجتها وإفادتهم",
          value: "",
        },
      },
    },
    section4: {
      id: "section4",
      title: "العمليات الداخلية",
      questions: {
        question14: {
          id: "question14",
          title: "مستوى الوضوح في إجراءات عمل الإدارات والقطاعات",
          value: "",
        },
        question15: {
          id: "question15",
          title:
            "مدى معرفة الموظفين بحقوقهم وواجباتهم واطلاعهم على السياسات والإجراءات",
          value: "",
        },
        question16: {
          id: "question16",
          title: "مدى رضاك عن نظام (سهل) للشركة في تسهيل العمليات الداخلية",
          value: "",
        },
        question17: {
          id: "question17",
          title: "يتم إطلاع الموظفين بمهام القطاعات/الإدارت الأخرى والصلاحيات",
          value: "",
        },
        question18: {
          id: "question18",
          title: "مستوى التعامل مع الطلبات من قبل قطاعات/إدارات الشركة وإنجازها في الوقت المطلوب ",
          value: "",
        },
      },
    },

    section6: {
      id: "section6",
      title: "بيئة العمل",
      questions: {
        question19: {
          id: "question19",
          title:
            "تتم المساندة والتوجيه من الرئيس المباشر لتحسين أدائي الوظيفي",
          value: "",
        },
        question20: {
          id: "question20",
          title:
            " تلقيت التدريب اللازم للقيام بأعمالي على الوجه المطلوب",
          value: "",
        },
        question21: {
          id: "question21",
          title:
            "مستوى إطلاعي على الأمور التي لها تأثير مباشر أو غير مباشر على مهامي",
          value: "",
        },
        question22: {
          id: "question22",
          title: " أعلم تماما ما هو العمل المطلوب/الدور الذي أقوم به",
          value: "",
        },
        question23: {
          id: "question23",
          title: 
          "يدعم المدراء التعاون بين قطاعات الشركة لتحقيق الأهداف المرجوة",
          value: "",
        },

        question24: {
          id: "question24",
          title:
            "هل  بيئة العمل مشجعة لتحقيق التوازن بين العمل ونواحي الحياة الأخرى",
          value: "",
        },
        question25: {
          id: "question25",
          title: "مدى تفهم وتعاون الشركة في حالة الظروف الشخصية الطارئة",
          value: "",
        },
        question26: {
          id: "question26",
          title: "مستوى رضاك بساعات الحضور والإنصراف المرن",
          value: "",
        },
        question27: {
          id: "question27",
          title:
            "مدى رضاك عن برامج الاندماج الوظيفي من خلال المبادرات والفعاليات والأنشطة الداخلية",
          value: "",
        },
        question28: {
          id: "question28",
          title: "الأنشطة والمبادرات التي تقوم بها الشركة منافسة للجهات الخارجية",
          value: "",
        },
        question29: {
          id: "question29",
          title: "كيف تصنف المعنويات العامة للموظفين",
          value: "",
        },
        question30: {
          id: "question30",
          title: "مدى اعتزازك وولائك للشركة",
          value: "",
        },
      },
    },
    section7: {
      id: "section7",
      title: "المسار المهني",
      questions: {
        question31: {
          id: "question31",
          title: "مدى سعادتك بانضمامك للشركة",
          value: "",
        },
        question32: {
          id: "question32",
          title: "مدى شعورك بالأمان كموظف في الشركة",
          value: "",
        },
        question33: {
          id: "question33",
          title: "في حال حصولك على عرض أفضل هل تفكر بالمغادرة",
          value: "",
        },
        question34: {
          id: "question34",
          title: "أزكي جهة عملي بصفتها مكان جيد للعمل",
          value: "",
        },
        question35: {
          id: "question35",
          title: "مستوى الرضا عن نظام الحوافز والبدلات",
          value: "",
        },
      },
    },
  },
};

const validate = (sections) => {
  const allQuestions = [];

  for (var section of Object.values(sections))
    for (var question of Object.values(section.questions))
      allQuestions.push(question);
  return !allQuestions.some((qiestion) => qiestion.value === "");
};

function updateBackend(url, key, data) {
  return fetch(url, {
    method: "PUT",
    headers: new Headers({
      Authorization: "Bearer " + key,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),

    body: JSON.stringify(data),
  });
}
function getBackend(url, key) {
  return fetch(url, {
    method: "get",
    headers: new Headers({
      Authorization: "Bearer " + key,
    }),
  });
}

function App() {

  // Generate the data structure with correct section indexing
let generatedData = {
  notes: "",
  sections: {}
};

let generatedData2 = {
  notes: "",
  sections: {}
};
let sectionIndexMap = {}; // Track section indices separately
let sectionCounter = 1; // Initialize section counter

questionsData.forEach((item) => {
  // Determine if the section already exists
  if (!generatedData2.sections[item.title]) {
    // Assign a new index to the section if it's a new section
    sectionIndexMap[item.title] = sectionCounter++;

    generatedData.sections[`section${sectionIndexMap[item.title]}`] = {
      id: `section${sectionIndexMap[item.title]}`,
      title: item.title,
      questions: {}
    };

    generatedData2.sections[item.title] = {
      id: `section${sectionIndexMap[item.title]}`,
      title: item.title,
      questions: {}
    };
  }

  // Generate a unique question ID based on the total count of all questions to ensure uniqueness
  const questionId = `question${Object.keys(generatedData.sections).reduce((acc, curr) => acc + Object.keys(generatedData.sections[curr].questions).length, 0) + 1}`;
  const questionIdx = `${Object.keys(generatedData.sections).reduce((acc, curr) => acc + Object.keys(generatedData.sections[curr].questions).length, 0) + 1}`;

  // Add the question to the appropriate section
  generatedData.sections[`section${sectionIndexMap[item.title]}`].questions[questionId] = {
    id: questionId,
    idx: questionIdx,
    title: item.question,
    value: "",
    options: answerGroups[item.answerGroupKey] // Dynamically assign the answer group
  };
});

const data=generatedData;
  const [state, setstate] = useState(data);
  const [valid, setValid] = useState(true);

  const [done, setdone] = useState(false);
  const [login, setlogin] = useState(false);
  const [expired, setexpired] = useState(false);
  const [token, settoken] = useState({
    value: "",
  });

  const setQuestionValue = (sectionId, questionId, value) => {
    let _state = { ...state };

    console.log("Current state: ", _state);
    console.log("Section ID: ", sectionId);
    console.log("Question ID: ", questionId);
  
    if (!_state.sections[sectionId]) {
      console.error("Section not found:", sectionId);
      return;
    }
  
    if (!_state.sections[sectionId].questions[questionId]) {
      console.error("Question not found:", questionId);
      return;
    }
  
   
    _state.sections[sectionId].questions[questionId].value = value;
    console.log("sectionId, questionId,value", _state);

    setstate(_state);
  };
  const location = useLocation();
  const history = useHistory();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Using an IIFE
    (async function anyNameFunction() {
      var url = location.pathname;

      var pollId = url.replace("/v/", "");
      pollId = pollId.replace("/", "");
      if (!login && pollId != "") {
        console.log(pollId);
        await getBackend(
          `https://survey-api.tatweertransit.com/poll/` + pollId,
          pollId
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.is_done == false) {
              setexpired(false);
              setlogin(true);
              settoken({
                value: pollId,
              });
              history.push("/");
            }
          })
          .catch((error) => {
            setexpired(true);
            setlogin(false);

            history.push("/");
          });
      }
    })();

    //    للاراء والمقترحات  والملاحظات الاخري فضلا امليء الصندوق

    // setlogin(true);
    //setexpired(true);
  });

  const Section = ({ section }) => {
    return (
      <div>
        <h1>{section.title}</h1>
        <hr />
        {Object.values(section?.questions).map((question) => {
          return <Question question={question} sectionId={section.id} />;
        })}
      </div>
    );
  };

  const Question = ({ question, sectionId }) => {
    return (
      <div>
        <h3>{question?.idx} - {question?.title}</h3>
        <OptionsContainer className="OptionsContainer">
          {question?.options?.map((option) => {
            return (
              <Option
                onClick={() => {
                  setQuestionValue(sectionId, question.id, option);
                }}
              >
                {question?.value === option ? (
                  <SelectedOption>{option}</SelectedOption>
                ) : (
                  <UnSelectedOption>{option}</UnSelectedOption>
                )}
              </Option>
            );
          })}
        </OptionsContainer>
      </div>
    );
  };

  if (!login)
    return (
      <ThankYou>
        <img src="/ttc_white_logo.png" />
        <br></br>
      </ThankYou>
    );

  if (expired)
    return (
      <ThankYou>
        <img src="/ttc_white_logo.png" />
        <br></br>
        <h2>انتهت صلاحية الاستبيان</h2>
        <h4> ربما قمت بالاجابه مسبقا ؟</h4>
      </ThankYou>
    );

  if (done)
    return (
      <ThankYou>
        <img src="/ttc_white_logo.png" />
        <br></br>
        <h2> شكرا لكم</h2>
      </ThankYou>
    );

  return (
    <SurveyContainer className="SurveyContainer">
      <Header>
        <h1>استبانة الرضا الوظيفي 2023م</h1>
        <Logo src="/ttc_white_logo.png" />
      </Header>

      <Modal
        isOpen={!valid}
        onRequestClose={() => setValid(true)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h1>الاستبيان غير مكتمل</h1>

        <div>الرجاء التأكد من إكمال الإجابة عن جميع الأسئلة</div>
        <hr />
        <button onClick={() => setValid(true)}>إغلاق</button>
      </Modal>
      {Object.values(state?.sections).map((section) => {
        return (
          <>
            <Section section={section} />
          </>
        );
      })}

      <NotesContainer>
        <h1>ملاحظات واقتراحات</h1>
        <TextArea
          value={state.notes}
          onChange={(event) => {
            console.log(event);
            let _state = { ...state };
            _state.notes = event.target.value;
            setstate(_state);
          }}
        />
      </NotesContainer>

      <Actions>
        <SaveButton
          onClick={() => {
            console.log(state);
            if (!validate(state.sections)) {
              setValid(false);
              return;
            }
            setdone(true);
            console.log(token);
            updateBackend(
              `https://survey-api.tatweertransit.com/poll/` + token.value,
              token.value,
              { response: state, is_done: true }
            );

            //axios.post("/add", state);
          }}
        >
          حفظ
        </SaveButton>
      </Actions>
    </SurveyContainer>
  );
}

const NotesContainer = styled.div`
  background: #f7f7f7;
  padding: 20px;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
`;
const Logo = styled.img`
  width: 100px;
`;
const Header = styled.div`
  background-color: #1d4a79;
  padding: 5px 30px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
`;

const Actions = styled.div`
  background-color: whitesmoke;
  padding: 10px;
  margin-top: 50px;
  position: relative;
`;
const SaveButton = styled.button`
  font-size: 1.5em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;
const SurveyContainer = styled.div`
  max-width: 1024px;
  margin: auto;
  padding: 10px;
`;

const SelectedOption = styled.div`
  border: 2px solid #a9a9a9;
  background-color: #f3f3f3;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;
const UnSelectedOption = styled.div`
  width:100%
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;
const Option = styled.div`
  margin: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
`;

const ThankYou = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: #ffffff;
  background-color: #1d4a79;
  flex-direction: column;
`;

export default App;
